import { createContext, cloneElement } from 'react';
import ApiService from './apiService';

const ServiceContext = createContext({});
const Provider = ServiceContext.Provider;
const service = new ApiService();

export function ServiceProvider({children, user}) {
    service.UserData = user;
    return (
    <Provider value={service}> 
        {cloneElement(children, { ServiceContext: ServiceContext })}
    </Provider>);
}

const ContextProvider = {
    ServiceProvider,
    ServiceContext
};

export default ContextProvider;