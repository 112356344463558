import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './views/router'
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: 'ap-southeast-2_IfdYSJCTi',
      userPoolClientId: '7satgpal8gei0t2vbg8fc8512h',
    },
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  }
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router />
  </React.StrictMode>
);

