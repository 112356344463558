import { useEffect, useCallback, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import HeaderTrayLight from "../containers/headerTrayLight";


export default function Leader() {
    const ctx = useOutletContext();
    const nav = useNavigate();
    const { id } = useParams();
    const defaultScoreArray = [{score: '', total_questions: '', username: '', total_elapsed_time: ''}];
    const [scores_red, setScoresRed] = useState(defaultScoreArray);
    const [scores_blue, setScoresBlue] = useState(defaultScoreArray);

    useEffect(() => {
        const dev_blue = "http://127.0.0.1:8080/leaderboard_blue"
        const prod_blue = "https://api.whisper.awsanz.com/leaderboard_blue"
        
        const fetchScoresBlue = async () => {
            const response = await fetch(prod_blue);
            const data = await response.json();
            setScoresBlue(data);
        }
        const intervalBlue = setInterval(fetchScoresBlue, 5000);
        return () => clearInterval(intervalBlue);
    }, []);

    useEffect(() => {
        const dev_red = "http://127.0.0.1:8080/leaderboard_red"
        const prod_red = "https://api.whisper.awsanz.com/leaderboard_red"
        
        // Call prod url using http long polling every 5 seconds
        const fetchScoresRed = async () => {
            const response = await fetch(prod_red);
            const data = await response.json();
            setScoresRed(data);
        }
        const intervalRed = setInterval(fetchScoresRed, 5000);
        return () => clearInterval(intervalRed);
    }, []);
    
    return (
        <Container className="main">
            <Stack gap={3} id="view-main">
                <HeaderTrayLight/>
                <Container style={{position:'relative'}} className="box-border">
                    <h2 style={{display:'flex', justifyContent:'center'}}>Leaderboard</h2>
                    <div style={{display:'flex'}}>
                        <div style={{display:'flex', flex: 1, flexDirection:'column', padding: 10}}>
                            {scores_blue.map((score_blue, index) => (
                                <h5 key={index} style={{color: '#CCFEFF'}}>
                                    {score_blue.username} - {score_blue.score}
                                </h5>
                            ))}
                        </div>
                        <div style={{display:'flex', flex: 1, flexDirection:'column', padding: 10}}>
                            {scores_red.map((score_red, index) => (
                                <h5 key={index} style={{color: '#FFCCCB'}}>
                                    {score_red.username} - {score_red.score}
                                </h5>
                            ))}
                        </div>
                    </div>
                </Container>
            </Stack>
        </Container>
    )
}