import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Events from "../lib/events";

const HeaderTrayLight = () => {
    const nav = useNavigate();

    const handleNav = () => {
       nav('/')
    }

    const welcome = () => {
        let date = new Date();
        return (date.getHours() > 12) ? "Afternoon" : "Morning";
    }

    return (
        <Container className="tray header">
            <Stack direction='vertical' gap={2}>
            <Stack direction='horizontal' gap={2}>
                <div onClick={handleNav} className="logoBlock d-flex justify-content-center">
                    <div className="titleBlock">
                    <h1 className="title">Amazon CodeWhisperer</h1>
                    <h2 className="subTitle">Code Challenge</h2>
                    </div>
                    <img className="logo"  src='/assets/icon_cw_serviceicon.svg' height="100px" alt="amazon codeWhisperer logo" />
                </div>
            </Stack>
            </Stack>
        </Container>
        )
}

export default HeaderTrayLight;
