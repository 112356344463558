import { useContext, useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import HeaderTray from "../containers/headerTray";
import Events from '../lib/events';


export default function Layout({ServiceContext, signOut, user}) {
  const ctx = useContext(ServiceContext);
  const nav = useNavigate();
  
  useEffect(() => {

    return () => {}
}, [])

  

  return (
      <Container className="app">
        <Stack gap={3} className="appStack">
          <HeaderTray signOut={signOut} user={user} />
          <Outlet context={ctx} user={user} />
        </Stack>
      </Container>
    );
}