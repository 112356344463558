import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { withAuthenticator } from '@aws-amplify/ui-react';
import Layout from './layout';
import Error from './error';
import Main from './main';
import Task from './task';
import Leader from './leader';

import ContextProvider from "../lib/context";



let ServiceProvider = ContextProvider.ServiceProvider;

const setRouter = (signOut, user) => { 
  return createBrowserRouter([
    {
      path: "/",
      element: <ServiceProvider children={<Layout signOut={signOut} user={user} />} user={user} />,
      errorElement: <Error />,
      children: [
        { path: "/", element: <Main />},
        { path: "/task/:id", element: <Task />}
      ]
    },
    {
      path: "/leaderboard",
      element: <ServiceProvider children={<Leader />} />,
      errorElement: <Error />,
    }
  ])
}

const Router = ({signOut, user}) => {
    const router = setRouter(signOut, user);
    return (<RouterProvider router={router} />)
}

export default withAuthenticator(Router);