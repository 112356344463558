import { useEffect, useCallback, useState } from "react";
import { useOutletContext, useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import Events from "../lib/events";
import TaskItem from "../components/taskItem";
import TaskList from "../components/taskList";


export default function Main() {
    const ctx = useOutletContext();
    const nav = useNavigate();
    const [isCW, setIsCW] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [taskList, setTaskList] = useState([]);
    const [errorText, setErrorText] = useState("");
    
    useEffect(() => {
        
        getProfile();
        /*
        
        Events.on("connection", vehicleConnection);
        Events.on("assetStatusChange", setAssetStatus);
        */
        return () => {}
    }, [])

    const getProfile = useCallback(async () => {
        ctx.GetProfile().then((data) => {
            if(data != null && data != undefined){
                setUserProfile(data);
                getTaskList();
            }
        });
    }, [userProfile, taskList]);

    const getTaskList = useCallback(async () => {
        ctx.TaskList().then((data) => {
            setTaskList(data);
        });
    }, [taskList]);

    const taskClickHandler = (taskNo) => {
        nav(`/task/${taskNo}`);
    }

    const teamSelectHandler = useCallback(async (usingCW) => {
        ctx.SetTeam(usingCW).then((data) => {
            if(data){
                console.log(`TeamSelect: ${data}`)
                setUserProfile(data)   
            } else {
                setErrorText("An error occured while selecting team. Please reload and try again.")
            }
            getTaskList();
        });
    }, [userProfile, taskList]);

    const teamSelect = () => {

        if (userProfile == null) {
            return (
                <div>
                    <p>
                        To join the <span style={{color: 'blue', fontWeight: 'bold'}}>BLUE Team</span> select the Using CodeWhisperer option below. <br/>
                        To make use of Amazon CodeWhisperer you will use your own IDE with Amazon Code CodeWhisperer enabled through the AWS Toolkit. <br/>
                        <a href="https://docs.aws.amazon.com/codewhisperer/latest/userguide/setting-up.html" target="_blank">Setup instructions </a> <br/>
                        <br/>
                        Or for the brave,<br/>
                        <br/>
                        To join the <span style={{color: 'red', fontWeight: 'bold'}}>RED Team</span> select Not using CodeWhisperer option is unselected and you submit your code without support.<br/>
                        To submit your code unsupported, you may use your IDE to code and copy & paste or you can code directly in the Task input form.
                    </p>

                    <Stack direction="horizontal" gap={4} className="col-md-5 mx-auto">
                        <div onClick={() => teamSelectHandler(true)} className="teamSelect center" style={{backgroundColor: 'blue'}}><h3>Blue Team</h3><p>Using CodeWhisperer</p></div>
                        <div onClick={() => teamSelectHandler(false)} className="teamSelect center" style={{backgroundColor: 'red'}}><h3>Red Team</h3><p>Not using CodeWhisperer</p></div>
                    </Stack>
                </div>)
        } else {
            return taskSelect()
        } 
    }

    const taskSelect = () => {
        if (userProfile.used_codewhisperer !== null && taskList != []) {
            return (
            <>
                <h3>Welcome {(userProfile.used_codewhisperer) ? <span style={{color: 'blue', fontWeight: 'bold'}}>Blue Team</span> : <span style={{color: 'red', fontWeight: 'bold'}}>Red Team</span>} challenger</h3>
                <TaskList list={taskList} />
            </>
            )
        }
    }
    
    return (
    <Container className="main">
        <Stack gap={3} id="view-main">
            <Container style={{position:'relative'}}>
            {teamSelect()}
            </Container>
        </Stack>
    </Container>)
}
