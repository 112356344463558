import { useNavigate } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import TaskItem from './taskItem';

export default function TaskList({list}) {
    const nav = useNavigate();
    
    const taskClickHandler = (taskNo) => {
        if(taskNo == 1){
            nav(`/task/${taskNo}`);
        } else {
            let prev = list[taskNo-2];
            if(prev && prev != undefined && prev != null){
                if(prev.isComplete){
                    nav(`/task/${taskNo}`);
                } else {
                    alert("Complete previous tasks to move on");
                }
            }
        }
    }

    const prevComplete = (el) => {
        let respose;
        let prev = list[el.taskNo-2]
        if(prev && prev != undefined && prev != null){
             respose = (prev.isComplete && !el.isComplete)
        } else {
            if (el.taskNo == 1) {
                respose = !el.isComplete
            } else {
                respose = false
            }
        }
        return respose
        //return (el.taskNo == 1) ? true : list[el.taskNo-2]?.isComplete
    }

    return (<>
        <h3>Tasks</h3>
        <ListGroup className="taskList">
            {list.map(el=>(<TaskItem key={el.taskNo} task={el} isEnabled={prevComplete(el)} clickHandler={taskClickHandler} />))}
        </ListGroup>
    </>)
}