const apiUrl = "https://api.whisper.awsanz.com";

export default class ApiService {   
    constructor(){
        this.userData = {};

    }

    get UserData() {
        return this.userData;
    }

    set UserData(user) {
        this.userData = user;
    }

    getObject(url){
        return new Promise((resolve, reject) => {
            try{
                fetch(`${apiUrl}${url}`)
                .then((response) => {
                    try{
                        response.json()
                        .then((json) => {
                            if(json != null && json != '' && json != undefined){
                                //console.log(json);
                                if (typeof json === typeof {}){ 
                                    resolve(json) 
                                } else {
                                    resolve(JSON.parse(json))
                                } 
                            }                         
                        }, (err) => {
                            throw new Error (err)
                        })
                    } catch (err){
                        console.log(err);
                        reject(err);
                    }
                },
                (err) => {
                    throw new Error (err)
                })
            } catch (err){
                console.log(err);
                reject(err);
            }
        })
    }
    
    TaskList() {
        return this.getObject(`/user/${this.userData.username}/tasks`)
        //return this.getObject(`/tasks`)
    }

    Task(taskId) {
        return this.getObject(`/tasks/${taskId}`)
    }

    UserTask(taskId) {
        return this.getObject(`/tasks/${taskId}/${this.userData.username}`)
    }

    GetProfile() {
        return this.getObject(`/user/${this.userData.username}/profile`)
    }

    SetTeam(isCW) {
        return new Promise((resolve, reject) => {
            try{
            fetch(`${apiUrl}/user/${this.userData.username}/profile`,{
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    "username": this.userData.username,
                    "used_codewhisperer": isCW,
                    "language": 'python'
                })
            }).then((response) => {
                response.json()
                .then((json) => {
                    if (typeof json === typeof {}){ 
                        resolve(json) 
                    } else {
                        resolve(JSON.parse(json))
                    } 
                }, (json) => {throw new Error (json)})
            })

            } catch (err){
                console.log(err);
                reject(err);
            }
        })
    }
    

    submitAnswer(taskId, code, startTime, submitTime){
        startTime = (startTime == 0) ? submitTime-100 : startTime;
        return new Promise((resolve, reject) => {
            try{

                fetch(`${apiUrl}/submit`,{
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        "question": taskId,
                        "code": code,
                        "username": this.userData.username,
                        "start_time": startTime,
                        "finish_time": submitTime,
                    })
                })
                .then((response) => {
                    response.json()
                    .then((json) => resolve(JSON.stringify(json)), (json) => {throw new Error (json)})
                }, 
                (json) => {throw new Error (json)})
 

            } catch (err){
                console.log(err);
                reject(err);
            }
        })
    }

}