import { useEffect, useCallback, useState } from "react";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Events from "../lib/events";

export default function Task() {
    const ctx = useOutletContext();
    const nav = useNavigate();
    const { id } = useParams();
    const [task, setTask] = useState("");
    const [code, setCode] = useState("");
    const [result, setResult] = useState(null);
    const [startTime, setStartTime] = useState(0);
    const [isMove, setIsMove] = useState(false)
    
    useEffect(() => {
        getTask(id);
        setIsMove(false);
        return () => {}
    }, [id, isMove])

    const getTask = useCallback(async (taskId) => {
        let start = new Date().getTime();
        ctx.Task(taskId).then((data) => {
            if(data == undefined || data == null || data.legth === 0 || Object.keys(data).length === 0){
                nav('/');
            } else {
                setStartTime(start);
                let que = data.question.split('\n').map(str => <span>{str}<br/></span>);
                setTask(que);
            }
        });
    }, [task]);

    const handleSubmit = async () => {
        //let submitCode = (code == "") ? document.getElementById("codeInput").value.toString() : code;
        let submitTime = new Date().getTime();
        let data = await ctx.submitAnswer(id, code, startTime, submitTime);
        setResult(data === 'true');
    };

    const nextTask = () => {
        let nextId = parseInt(id)+1
        setCode("");
        setResult(null);
        setIsMove(true);
        nav(`/task/${nextId}`);
    }

    const status = () => {
        let text = "";
        if(result == null){
            text = 'Task started';
        } else {
            text = (result) ? <p style={{ color: 'green' }}>Complete!! Move to the next task.</p> : <p style={{ color: 'red' }}>Sorry, try again!</p>
        }
        return (<Container style={{position:'relative'}} className="box-border">
        <h2>Task No.{id}</h2>
        <p className="detail">{task}</p>
        <p className="response">{text}</p>
        {(result == true) ? <Button variant="success" onClick={nextTask} >Next Task</Button> : null}
        </Container>)
    }
    
    return (
    <Container className="task">
        <Stack gap={3} id="view-task">
            {status()}
            <Container style={{position:'relative'}}>
            <InputGroup>
                <InputGroup.Text>Code Form</InputGroup.Text>
                <Form.Control as="textarea" aria-label="Code Form" rows={20} value={code} onChange={(e)=>setCode(e.target.value)} id="codeInput" />
            </InputGroup>
            <Stack direction="horizontal" gap={2}>
                <span className="startTime">Start: {startTime}</span>
                <Button variant="primary" size="lg" onClick={handleSubmit} className="ms-auto">
                Submit Code
                </Button>
            </Stack>
            </Container>
        </Stack>
    </Container>)
}

