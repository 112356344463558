import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Events from "../lib/events";

const HeaderTray = ({signOut, user}) => {
    const nav = useNavigate();

    const handleNav = () => {
       nav('/')
    }

    const welcome = () => {
        let date = new Date();
        return (date.getHours() > 12) ? "Afternoon" : "Morning";
    }

    return (
        <Container className="tray header">
            <Stack direction='vertical' gap={2}>
            <Stack direction='horizontal' gap={2}>
                <Container style={{height:'100px', position:'relative'}}>
                    <div className={"user"}>
                        <div>Good {welcome()}, <strong>{user.username}</strong></div>
                        <button onClick={signOut}>Sign out</button>
                    </div>
                </Container>
                <div onClick={handleNav} className="logoBlock d-flex justify-content-center">
                    <div className="titleBlock">
                    <h1 className="title">Amazon CodeWhisperer</h1>
                    <h2 className="subTitle">Code Challenge</h2>
                    </div>
                    <img className="logo"  src='/assets/icon_cw_serviceicon.svg' height="100px" alt="amazon codeWhisperer logo" />
                </div>
            </Stack>
            <Container style={{position:'relative'}} className="box-border">
                    <h2>Instruction</h2>
                    <p className="detail">
                        Each Task in the list is a code challenge. <br/>
                        Answer a code challenge correclty to move on to the next task. Each Task gets more difficult as you progress. <br/>
                        <br/>
                        Your score is calculated by the time it takes to submit a correct answer, and your position on the leaderbaord is calculated by your cumulative task completion time and difficuly multiplier for the most difficult challenge completed. <br/>
                        <br/>
                        You will be using Python as the language of choice for this challenge. <br/>
                        Submit your answer to the code challenge by copy & pasting your code into the Task input form.
                    </p>
                </Container>
            </Stack>
        </Container>
        )
}

export default HeaderTray;
